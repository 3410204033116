<template>
  <div>
    <div class="search">
      <div class="title">优惠活动列表</div>
      <hr />
      <el-form :inline="true" class="top">
        <el-form-item label="名称:">
          <el-input v-model="params.title"></el-input>
        </el-form-item>
        <!-- <el-form-item label="手机号:">
              <el-input v-model="params.phone"></el-input>
            </el-form-item>
            <el-form-item label="时间:">
              <el-date-picker
                :editable="false"
                :picker-options="pickerOptions0"
                value-format="yyyy-MM-dd HH:mm:ss"
                v-model="date"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              >
              </el-date-picker>
            </el-form-item> -->
        <el-form-item>
          <el-button type="primary" @click="getList(1)">查询</el-button>
          <el-button type="primary" @click="add">添加</el-button>
          <!-- <el-button type="primary" @click="reset">重置</el-button> -->
        </el-form-item>
      </el-form>
    </div>
    <el-table
      :data="tableData"
      border
      tooltip-effect="dark"
      :row-key="rowKey"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      align="center"
    >
      <!-- <el-table-column type="selection" width="55" :reserve-selection="true" align="center"> </el-table-column> -->
      <el-table-column type="index" width="50" align="center"> </el-table-column>
      <el-table-column prop="title" label="活动名称" align="center"> </el-table-column>
      <el-table-column prop="prodName" label="商品名称" align="center"> </el-table-column>

      <el-table-column prop="prodTime" label="活动时间" width="200" align="center">
      </el-table-column>
      <el-table-column prop="couponName" label="优惠名称" width="180" align="center">
      </el-table-column>
      <el-table-column prop="couponInfo" label="优惠权益" align="center">
      </el-table-column>
      <el-table-column prop="skip" label="跳转页面" width="200" align="center">
      </el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="send(scope.row)">发送</el-button>
          <el-button type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <!-- <div>
                  <el-button type="primary" @click="allCheckVip">批量修改vip</el-button>
                <el-button type="primary" @click="allOpe">批量操作</el-button>
             </div> -->
      <div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="params.currentPage"
          :page-sizes="[5, 10, 20]"
          :page-size="params.pageSize"
          layout="total,sizes,prev,pager,next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="40%"
      @close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form :model="addList" :rules="addRules" ref="addRuleFormRef" label-width="100px">
        <el-form-item label="活动名称:" prop="title">
          <el-input maxlength="20" v-model="addList.title" style="width: 400px"></el-input>
          <div style="color:red">只能输入20个字符</div>
        </el-form-item>
        <el-form-item label="商品名称:" prop="prodName">
          <el-input maxlength="20" v-model="addList.prodName" style="width: 400px"></el-input>
          <div style="color:red">只能输入20个字符</div>
        </el-form-item>
        <el-form-item label="活动日期:" prop="addTime" style="width: 400px">
          <el-date-picker
            v-model="addList.addTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束日期:" prop="endTime" style="width: 400px">
          <el-date-picker
            v-model="addList.endTime"
            value-format="yyyy-MM-dd"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="优惠名称:" prop="couponName">
          <el-input maxlength="20" v-model="addList.couponName" style="width: 400px"></el-input>
          <div style="color:red">只能输入20个字符</div>
        </el-form-item>
        <el-form-item label="优惠权益:" prop="couponInfo">
          <el-input maxlength="20" v-model="addList.couponInfo" style="width: 400px"></el-input>
          <div style="color:red">只能输入20个字符</div>
        </el-form-item>

        <el-form-item label="跳转类型:" prop="skipType">
          <el-select
            v-model="addList.skipType"
            placeholder="请选择"
            @change="addList.skipId = ''"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="跳转类型Id:"
          v-if="skipArr.includes(addList.skipType)"
          prop="skipId"
        >
          <el-input v-model.number="addList.skipId" style="width: 100px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { activltyNoticeList, activltyNoticeListAdd,sendMsg } from "../../api/notice.js";
export default {
  data() {
    return {
      dialogVisible: false,
      total: 0,
      options: [
        {
          value: 0,
          label: "首页",
        },
        {
          value: 1,
          label: "酒店列表",
        },
        {
          value: 2,
          label: "酒店详情",
        },
        {
          value: 3,
          label: "门票列表",
        },
        {
          value: 4,
          label: "门票详情",
        },
        {
          value: 5,
          label: "线路列表",
        },
        {
          value: 6,
          label: "线路详情",
        },
        {
          value: 7,
          label: "拼团列表",
        },
        {
          value: 8,
          label: "秒杀列表",
        },
        {
          value: 9,
          label: "精选商城商品详情",
        },
        {
          value: 10,
          label: "拼团详情",
        },
        {
          value: 11,
          label: "秒杀详情",
        },
        {
          value: 12,
          label: "活动页",
        },
        {
          value: 13,
          label: "优惠券列表",
        },
        {
          value: 14,
          label: "线路分类列表",
        },
        {
          value: 15,
          label: "加入万小店",
        },
        {
          value: 16,
          label: "万旅卡详情",
        },
        {
          value: 17,
          label: "关于我们 协议详情",
        },
      ],
      skipArr: [2, 4, 6, 9, 10, 11, 12, 14, 15, 16, 17],
      params: {
        currentPage: 1,
        pageSize: 5,
        sendType: 2,
        title: "",
        // nickName: "",
        // phone: "",
        // beginTime: "",
        // endTime: "",
      },
      addList: {
        sendId: 0,
        addTime: "",
        prodName: "",
        title: "",
        skipType: "",
        skipId: "",
        couponName: "",
        couponInfo: "",
        endTime: "",
      },
      addRules: {
        addTime: [{ required: true, message: "请选择活动日期", trigger: "blur" }],
        prodName: [
          { required: true, message: "请输入商品名称", trigger: "blur" },
          { max: 20, message: "不能超出 20 个字符", trigger: "blur" },
        ],
        title: [
          { required: true, message: "请输入活动名称", trigger: "blur" },
          { max: 20, message: "不能超出 20 个字符", trigger: "blur" },
        ],
        skipType: [{ required: true, message: "请选择跳转类型", trigger: "blur" }],
        skipId: [{ required: true, message: "请输入跳转类型Id", trigger: "blur" }],
        couponName: [
          { required: true, message: "请输入优惠名称", trigger: "blur" },
          { max: 20, message: "不能超出 20 个字符", trigger: "blur" },
        ],
        couponInfo: [
          { required: true, message: "请输入优惠权益", trigger: "blur" },
          { max: 20, message: "不能超出 20 个字符", trigger: "blur" },
        ],
        endTime: [{ required: true, message: "请选择结束日期", trigger: "blur" }],
      },
      tableData: [],
      date: null,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now() - 8.64e6;
        },
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    send(row){
      this.$confirm("确认发送吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
       
          //   this.addList.prodFee = Number(this.addList.prodFee);
          sendMsg({sendId:row.sendId}).then((res) => {
            if (res.data.code == 0) {
              this.$message({
                type: "success",
                message: "发送成功!",
              });
           
              this.getList();
            } else {
              this.$message.error(res.data.msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消发送",
          });
        });
    },
    add() {
      this.addList.addTime = "";
      this.addList.prodName = "";
      this.addList.title = "";
      this.addList.skipType = "";
      this.addList.skipId = "";
      this.addList.couponName = "";
      this.addList.couponInfo = "";
      this.addList.sendId = 0

      this.dialogVisible = true;
    },
    edit(row){
      this.addList.addTime = row.prodTime;
      this.addList.prodName = row.prodName;
      this.addList.title = row.title;
      this.addList.skipType = row.skipType;
      this.addList.skipId = row.skipId;
      this.addList.couponName = row.couponName;
      this.addList.couponInfo = row.couponInfo;
      this.addList.endTime = row.endTime;
      this.addList.sendId = row.sendId;
      this.dialogVisible = true;
    

    },
    rowKey(row) {
      return row.userId;
    },
    async getList(page = "") {
      if (page) {
        this.params.currentPage = page;
      }
      //   this.params.beginTime = this.date ? this.date[0] : "";
      //   this.params.endTime = this.date ? this.date[1] : "";
      const { data } = await activltyNoticeList(this.params);
      if (data.code == 0) {
        this.tableData = data.list;
        this.total = data.pagination.total;
      } else {
      }
    },
    handleSizeChange(e) {
      this.params.pageSize = e;
      this.getList();
    },
    // 页数改变
    handleCurrentChange(e) {
      this.params.currentPage = e;
      this.getList();
    },
    reset() {
      this.params.currentPage = 1;
      this.params.pageSize = 5;
      this.params.phone = "";
      this.params.beginTime = "";
      this.params.endTime = "";
      this.date = null;
      this.getList();
    },
    submit() {
      this.$refs.addRuleFormRef.validate((valid) => {
        if (valid) {
          console.log(this.addList);
          this.$confirm("确认提交吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              this.dialogVisible = false;
              //   this.addList.prodFee = Number(this.addList.prodFee);
              activltyNoticeListAdd(this.addList).then((res) => {
                if (res.data.code == 0) {
                  this.$message({
                    type: "success",
                    message: "提交成功!",
                  });
                  this.dialogVisible = false;
                  this.getList();
                } else {
                  this.$message.error(res.data.msg);
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消提交",
              });
            });
        } else {
          this.$message.error("请填写完整信息");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.title {
  padding: 10px 0;
}
.top {
  margin-top: 20px;
}
.block {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
</style>
